let headers = {
  'Content-Type': 'application/json',
  'Authorization': '' // This will be set via setAuthorization
};
class APIClient {
  

  /**
   * Helper function to fetch and handle responses
   */
  async fetch(url: string, options: any) {
    const response = await fetch(`${url}`, {
      ...options,
      headers: {
        ...headers,
      }
    });
    const jsonData = await response.json();
    return {
      data: jsonData, // this will be the response data
      status: response.status // capturing the status code
    };
  }

  handleError(status: number, errorData: any) {
    switch (status) {
      case 500:
        return "Internal Server Error";
      case 401:
        return "Invalid credentials";
      case 404:
        return "Sorry! the data you are looking for could not be found";
      default:
        return errorData.message || 'An unknown error occurred';
    }
  }

  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return this.fetch(url, {
      method: 'GET'
    });
  };

  /**
   * Post given data to url
   */
  create = (url: string, data?: {}) => {
    return this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return this.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return this.fetch(url, {
      method: 'DELETE',
      ...config
    });
  };

  /*
   File upload update method
  */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    return this.fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  /*
   File upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    return this.fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
};

/**
 * Sets the default authorization
 */
const setAuthorization = (token: string) => {
  headers.Authorization = `Bearer ${token}`;
};

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  }
  return JSON.parse(user);
};

export { APIClient, setAuthorization, getLoggedinUser };
