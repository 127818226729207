import React, { useEffect, useRef, useCallback } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// hooks
import { useProfile } from "../../../hooks";
import { createSelector } from "reselect";
// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

// interface
import { MessagesTypes } from "../../../data/messages";

interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
}
const Conversation = ({
  chatUserDetails,
  chatUserConversations,
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { userProfile } = useProfile();

  const errorData = createSelector(
    (state : any) => state.Chats,
    (state) => ({
      getUserConversationsLoading: state.getUserConversationsLoading,
      isMessageForwarded: state.isMessageForwarded,
    })
  );
  // Inside your component
  const { getUserConversationsLoading } = useAppSelector(errorData);

  const messages = chatUserConversations.messages && chatUserConversations.messages.length ? chatUserConversations.messages : [];

  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current.getScrollElement().scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  }, []);
  useEffect(() => {
    if (chatUserConversations.messages) {
      scrollElement();
    }
  }, [chatUserConversations.messages, scrollElement]);

  return (
    <AppSimpleBar scrollRef={ref} className="chat-conversation p-3 p-lg-4 positin-relative">
      {getUserConversationsLoading && <Loader />}
      <ul className="list-unstyled chat-conversation-list" id="chat-conversation-list">
        {(messages || []).map((message: MessagesTypes, key: number) => {
          return (
            <Message message={message} key={key} chatUserDetails={chatUserDetails} />
          );
        })}
      </ul>
    </AppSimpleBar>
  );
};

export default Conversation;
