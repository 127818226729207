import React, { useState, useEffect } from "react"; // useEffect,
import { createSelector } from "reselect";
import { Button, Form, Input } from "reactstrap";
import { Link } from "react-router-dom";

import DirectMessages from "./DirectMessages";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { getDirectMessages, getArchivedMessages, changeSelectedChat, getChatUserConversations, getTags } from "../../../redux/actions";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";

// import Favourites from "./Favourites";
// import DirectMessages from "./DirectMessages";
// import Archive from "./Archive";
import { CHATS_TABS } from "../../../constants";
import { PinTypes } from "../../../data";

interface IndexProps {}
const Index = (props: IndexProps) => {
	// global store
	const { dispatch, useAppSelector } = useRedux();
	const [selectedTags, setSelectedTags] = useState<PinTypes[]>([]);
	const [searchKeyword, setSearchKeyword] = useState('');

	/*
	tab handeling
	*/
	const [active, setActive] = useState(CHATS_TABS.DEFAULT);
	const onChangeTab = (tab: CHATS_TABS) => {
		setActive(tab);
		if (tab === CHATS_TABS.ARCHIVE) {
			dispatch(getArchivedMessages());
		}
	};

	const errorData = createSelector(
		(state : any) => state.Contacts,
		(props : any) => props.Chats,
		(state, props) => ({
			selectedChat: props.selectedChat,
			directMessages: props.directMessages,
			archiveMessages: props.archiveMessages,
			tagsOptions: props.tags,
		})
	);

	// Inside your component
	const { selectedChat, directMessages, tagsOptions, archiveMessages } = useAppSelector(errorData);

	// get data
	useEffect(() => {
		dispatch(getDirectMessages());
		dispatch(getTags());
	}, [dispatch]);

	const filterUser = directMessages.filter((user: any) => {
		if (selectedTags.length === 0 && searchKeyword.length === 0) {
			return true;
		}

		if (selectedTags.length > 0) {
			const userTags = user.tags || [];
			const selectedTagsIds = selectedTags.map((tag: PinTypes) => tag.id);
			if (selectedTagsIds.some((tagId: string) => userTags.includes(tagId))) {
				return true;
			}
		}

		if (searchKeyword.length > 0) {
			if (user.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
				return true;
			}
		}

		return false;
	});

	const filterArchive = archiveMessages?.filter((user: any) => {
		if (selectedTags.length === 0 && searchKeyword.length === 0) {
			return true;
		}

		if (selectedTags.length > 0) {
			const userTags = user.tags || [];
			const selectedTagsIds = selectedTags.map((tag: PinTypes) => tag.id);
			if (selectedTagsIds.some((tagId: string) => userTags.includes(tagId))) {
				return true;
			}
		}

		if (searchKeyword.length > 0) {
			if (user.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
				return true;
			}
		}

		return false;
	});

	const onSelectChat = (id: string | number, companyID: number) => {
		dispatch(getChatUserConversations(id, companyID));
		dispatch(changeSelectedChat(id));
	};

	return (
		<div>
			<div className="px-4 pt-4">
				<div className="d-flex align-items-start">
					<div className="flex-grow-1">
						<h4 className="mb-4">Chats</h4>
					</div>
				</div>
				<Form>
					<div className="input-group mb-3">
						<Input
							id="serachChatUser"
							type="text"
							className="form-control bg-light border-0 pe-0"
							placeholder="Search here.."
							value={searchKeyword}
							onChange={(e) => setSearchKeyword(e.target.value)}
						/>
					</div>
				</Form>
			</div>
			<div className="px-4 overflow-x-auto">
				<div className="d-flex align-items-center">
					{
						(tagsOptions || []).map((tag: PinTypes, key: number) => {
							const isSelected = selectedTags.some((selectedItem: PinTypes) => selectedItem.id === tag.id);
							
							return (
								<button
									key={key}
									className={`btn ${ isSelected ? 'btn-primary' : 'btn-light' } btn-sm me-2 mb-2 whitespace-nowrap`}
									onClick={() => {
										if (isSelected) {
											setSelectedTags(selectedTags.filter((selectedItem: PinTypes) => selectedItem.id !== tag.id));
										} else {
											setSelectedTags([...selectedTags, tag]);
										}
									}}
								>
									{tag.title}
								</button>
							)
						})
					}
				</div>
			</div>

			<AppSimpleBar className="chat-room-list">
				{/* Start chat-message-list */}
				{/* <DirectMessages
					users={directMessages}
					selectedChat={selectedChat}
					onSelectChat={onSelectChat}
					/> */}
				{active === CHATS_TABS.DEFAULT && (
					<>
						{/* favourite */}
						{/* <Favourites
							users={favourites}
							selectedChat={selectedChat}
							onSelectChat={onSelectChat}
						/> */}

						<h5 className="text-center mt-2 mb-3">
							<Link to="#" className="mb-3 px-4 mt-4 font-size-11 text-primary" onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}>
								View Archived <i className="bx bxs-archive-out align-middle" />
							</Link>
						</h5>

						{/* direct messages */}
						<DirectMessages
							users={filterUser}
							selectedChat={selectedChat as string | number} // Explicitly type the selectedChat prop
							onSelectChat={onSelectChat}
							tagsOptions={tagsOptions}
							selectedTags={selectedTags}
							type="Inbox"
						/>

					</>
				)}
				{active === CHATS_TABS.ARCHIVE && (
					<>
						<h5 className="text-center mt-2 mb-3">
							<Link to="#" className="mb-3 px-4 mt-4 font-size-11 text-primary" onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}>
								View Inbox <i className="bx bxs-inbox align-middle" />
							</Link>
						</h5>
						<DirectMessages
							users={filterArchive}
							selectedChat={selectedChat as string | number} // Explicitly type the selectedChat prop
							onSelectChat={onSelectChat}
							tagsOptions={tagsOptions}
							selectedTags={selectedTags}
							type="Archive"
						/>
						
					</>
				)}

				{/* End chat-message-list */}
			</AppSimpleBar>
		</div>
	);
};

export default Index;
