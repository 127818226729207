// constants
import { TABS, LAYOUT_MODES } from "../../constants/index";

import { LayoutActionTypes, LayoutState } from "./types";

// Helper function to get initial state from localStorage
const getInitialState = (): LayoutState => {
  const savedState = localStorage.getItem("layoutState");
  if (savedState) {
    return JSON.parse(savedState);
  } else {
    return {
      activeTab: TABS.CHAT,
      layoutMode: LAYOUT_MODES.LIGHT,
    };
  }
};

const INIT_STATE: LayoutState = getInitialState();

const Layout = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_TAB: {
        const newState = {
          ...state,
          activeTab: action.payload,
        };
        saveStateToLocalStorage(newState);
        return newState;
      }
    case LayoutActionTypes.CHANGE_LAYOUT_MODE: {
        const newState = {
          ...state,
          layoutMode: action.payload.layoutMode,
        }
        saveStateToLocalStorage(newState);
        return newState;
      }
    default:
      return { ...state };
  }
};

// Save the state to localStorage whenever it changes
const saveStateToLocalStorage = (state: LayoutState) => {
  localStorage.setItem("layoutState", JSON.stringify(state));
};

export default Layout;