import { APIClient, setAuthorization } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getFavourites = () => {
  return api.get(url.GET_FAVOURITES);
};

const getDirectMessages = () => {
  setAuthorization(getToken());
  return api.get(url.GET_CHAT_USER_CONVERSATIONS)
    .then(response => {
      if (response.status === 200) {
        return response.data.conversations;
      } else {
        return [];
      }
    })
    .catch(error => {
      //todo: log error
      return [];
      // return {
      //   data: {}, // Default or extracted error information
      //   status: 500 // Default or extracted error status
      // };
    });
};

const getArchivedMessages = () => {
  setAuthorization(getToken());
  return api.get(url.GET_ARCHIVED_MESSAGES)
    .then(response => {
      if (response.status === 200) {
        return response.data.conversations;
      } else {
        return [];
      }
    })
    .catch(error => {
      //todo: log error
      return [];
      // return {
      //   data: {}, // Default or extracted error information
      //   status: 500 // Default or extracted error status
      // };
    });
}

const getChatUserConversations = (id: string | number, companyID: number) => {
  setAuthorization(getToken());
  return api.get(url.GET_CHAT_BY_ID + '?id=' + id + '&companyID=' + companyID, {
    params: { id, companyID },
  });
};

const sendMessage = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.SEND_MESSAGE, data);
};

const receiveMessage = (id: string | number) => {
  return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const receiveMessageFromUser = (id: string | number) => {
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
    params: { id },
  });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  return api.delete(url.DELETE_MESSAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId },
  });
};

const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

const readConversation = (id: string | number) => {
  return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

const getTags = () => {
  setAuthorization(getToken());
  return api.get(url.GET_TAG_LIST)
    .then(response => {
      if (response.status === 200) {
        return response.data.tags;
      } else {
        return [];
      }
    })
    .catch(error => {
      //todo: log error
      return [];
      // return {
      //   data: {}, // Default or extracted error information
      //   status: 500 // Default or extracted error status
      // };
    });

}

const addTags = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.ADD_TAG, data);
}

const deleteTags = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.DELETE_TAG, data);
}

const setAutoReplyOff = (data: any) => {
  setAuthorization(getToken());
  return api.create(url.SET_AUTO_REPLY_OFF, data);
}

const setAutoReplyOn = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.SET_AUTO_REPLY_ON, data);
}

const setArchive = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.SET_ARCHIVE, data);
}

const setUnArchive = (data: object) => {
  setAuthorization(getToken());
  return api.create(url.SET_UN_ARCHIVE, data);
}

const getToken = () => {
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    const user = JSON.parse(authUser);
    return user.token;
  }
  return "";

}

export {
  getFavourites,
  getDirectMessages,
  getArchivedMessages,
  getChatUserConversations,
  sendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  readConversation,
  deleteImage,
  getTags,
  addTags,
  deleteTags,
  setAutoReplyOff,
  setAutoReplyOn,
  setArchive,
  setUnArchive,
};
