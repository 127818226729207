// export const BACKEND_URL = "http://localhost:5087/api/v1.0";
// export const BACKEND_URL = "https://admin.chatassistant.blanksoft.dev/api/v1.0";
export const BACKEND_URL =  process.env.REACT_APP_BACKEND_URL; //'https://admin.chatassistant.blanksoft.dev/api/v1.0'; //process.env.REACT_APP_BACKEND_URL;

//auth
export const POST_LOGIN = BACKEND_URL + "/auth/login";
export const USER_CHANGE_PASSWORD = "/account/change-password";


// profile & settings
export const GET_PROFILE_DETAILS = "/account/details";
export const GET_USER_SETTINGS = "/account/settings";
export const UPDATE_SETTINGS = "/account/update-settings";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// tags
export const GET_TAG_LIST = BACKEND_URL + "/chat/tags";
export const ADD_TAG = BACKEND_URL + "/chat/add-tag";
export const DELETE_TAG = BACKEND_URL + "/chat/remove-tag";

// chats
export const GET_DIRECT_MESSAGES = BACKEND_URL + "/chat/Conversations";
export const GET_ARCHIVED_MESSAGES = BACKEND_URL + "/chat/Archived-Conversations";
export const GET_CHAT_USER_CONVERSATIONS = BACKEND_URL + "/chat/conversations";
export const GET_CHAT_BY_ID = BACKEND_URL + "/chat/conversation";
export const SEND_MESSAGE = BACKEND_URL + "/chat/send-message";
export const SET_AUTO_REPLY_OFF = BACKEND_URL + "/chat/Pause-GPT-Reply";
export const SET_AUTO_REPLY_ON = BACKEND_URL + "/chat/Resume-GPT-Reply";
export const SET_ARCHIVE = BACKEND_URL + "/chat/Set-Archive";
export const SET_UN_ARCHIVE = BACKEND_URL + "/chat/Unset-Archive";

export const GET_FAVOURITES = "/get-favourites";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";

