import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";

// hooks
import { useRedux } from "../../../hooks/index";

// components
import AddPinnedTabModal from "../../../components/TagsModal";

// interface
import { PinTypes } from "../../../data/chat";

// actions
import { changeSelectedChat } from "../../../redux/actions";

interface ProfileImageProps {
  chatUserDetails: any;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
}
const ProfileImage = ({ chatUserDetails, onCloseConversation, onOpenUserDetails }: ProfileImageProps) => {
  const fullName = chatUserDetails && chatUserDetails.title ? chatUserDetails.title : '-';
  const shortName = `${fullName.charAt(0)}`;
  const phoneNumber = chatUserDetails && chatUserDetails.phoneNumber ? chatUserDetails.phoneNumber : '-';

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-2">
        <Link to="#" onClick={onCloseConversation} className="text-muted font-size-24 p-2">
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div>
      <div className="flex-grow-1 overflow-hidden">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 chat-user-img align-self-center me-3 ms-0">
            {chatUserDetails?.profileImage ? (
              <img src={chatUserDetails.profileImage} className="rounded-circle avatar-sm" alt="" />
            ) : (
              <div className="avatar-sm align-self-center">
                <span className="avatar-title rounded-circle text-uppercase text-white bg-primar">
                  <span className="username"> {shortName}</span>
                </span>
              </div>
            )}
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h6 className="text-truncate mb-0 font-size-18">
              <Link to="#" onClick={onOpenUserDetails} className="user-profile-show text-reset">
                {fullName}
              </Link><br/>
              <span className="username"> {phoneNumber} </span>
            </h6>
            <p className="text-truncate text-muted mb-0"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn dropdown-toggle" type="button">
        <i className="bx bx-search"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
        <div className="search-box p-2">
          <Input type="text" className="form-control" placeholder="Search.." />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
interface MoreProps {
  isArchive: boolean;
}
const More = ({ isArchive }: MoreProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="d-flex justify-content-between align-items-center d-lg-none user-profile-show" to="#">
          View Profile <i className="bx bx-user text-muted"></i>
        </DropdownItem>
        <DropdownItem className="d-flex justify-content-between align-items-center" to="#">
          Muted <i className="bx bx-microphone-off text-muted"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

interface PinnedAlertProps {
  onOpenPinnedTab: () => void;
  tags: Array<any>;
}
const PinnedAlert = ({ onOpenPinnedTab, tags }: PinnedAlertProps) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <Alert
      color="warning"
      isOpen={visible}
      toggle={onDismiss}
      className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
      role="alert"
    >
      <div className="d-flex align-items-start bookmark-tabs">
        <div className="tab-list-link">
          <Link to="#" className="tab-links" onClick={onOpenPinnedTab}>
            <i className="ri-pushpin-fill align-middle me-1"></i>
              {
                tags && tags.length
                  ? tags.length === 1 ? tags.length + " Tag" : tags.length + " Tags"
                  : "No Tag"
              } 
          </Link>
        </div>
      </div>
    </Alert>
  );
};

interface UserHeadProps {
  chatUserDetails: any;
  onOpenUserDetails: () => void;
  tags: Array<any>;
  tagOptions: Array<PinTypes>;
  chatID: string;
  companyID: number;
}
const UserHead = ({ chatUserDetails, onOpenUserDetails, tags, tagOptions, chatID, companyID }: UserHeadProps) => {
  // global store
  const { dispatch } = useRedux();

  /*
  pinned tab modal
  */
  const [isOpenPinnedTabModal, setIsOpenPinnedTabModal] = useState<boolean>(false);
  const onOpenPinnedTab = () => {
    setIsOpenPinnedTabModal(true);
  };
  const onClosePinnedTab = () => {
    setIsOpenPinnedTabModal(false);
  };

  /*
  mobile menu chat conversation close
  */
  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  return (
    <div className="p-3 p-lg-4 user-chat-topbar">
      <Row className="align-items-center">
        <Col sm={4} className="col-8">
          <ProfileImage
            chatUserDetails={chatUserDetails}
            onCloseConversation={onCloseConversation}
            onOpenUserDetails={onOpenUserDetails}
          />
        </Col>
        <Col sm={8} className="col-4">
          <ul className="list-inline user-chat-nav text-end mb-0">
            {/*
            <li className="list-inline-item  ">
              <Search />
            </li>
            */}
            
            {/*
            <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
              <button
                onClick={onOpenUserDetails}
                type="button"
                className="btn nav-btn user-profile-show"
              >
                <i className="bx bxs-info-circle"></i>
              </button>
            </li>
            */}

            {/*
            <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
              <More onDelete={onDelete} isArchive={chatUserDetails.isArchived} />
            </li>
            */}

          </ul>
        </Col>
      </Row>
      <PinnedAlert onOpenPinnedTab={onOpenPinnedTab} tags={tags} />
     
      {isOpenPinnedTabModal && (
        <AddPinnedTabModal
          isOpen={isOpenPinnedTabModal}
          onClose={onClosePinnedTab}
          selectedTags={tags}
          tagOptions={tagOptions}
          chatID={chatID}
          companyID={companyID}
        />
      )}
    </div>
  );
};

export default UserHead;
