import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { AuthLoginActionTypes } from "./types";
import { authLoginApiResponseSuccess, authLoginApiResponseError } from "./actions";

//Include Both Helper File with needed methods
import { postJwtLogin } from "../../../api/index";


function* loginUser({ payload: { user } }: any) {
  try {
    const { data } = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
      token: user.token,
    });

    if (data.success) {
      yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, {data: data.profile}));
      localStorage.setItem("authUser", JSON.stringify(data.profile));
    } else {
      yield put(authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, data.message));
    }

    
  } catch (error: any) {
    // Assuming error handling also includes setting appropriate messages
    yield put(authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error.message));
  }
}


function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

function* loginSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
}

export default loginSaga;
