import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form, Button } from "reactstrap";

// router
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from 'firebase/messaging';

// hooks
import { useProfile, useRedux } from "../../hooks/index";
import { createSelector } from "reselect";

//actions
import { loginUser } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBV4B8jjMCuJAA-Tab3gWr8T5X3YHfSVN4",
  authDomain: "chatassistant-478e6.firebaseapp.com",
  projectId: "chatassistant-478e6",
  storageBucket: "chatassistant-478e6.appspot.com",
  messagingSenderId: "1056359695751",
  appId: "1:1056359695751:web:195e58425851b6a2023f22",
  measurementId: "G-0N16LYYBYS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    await Notification.requestPermission();
    const token = await getToken(messaging, { vapidKey: 'BCMdMLEOfWjd6pENTag2sifPxQnaINlGRSv6PIwlgTAVFekIV5aZPpeLMInXfk-F110esVLzRQ8a7f-Xs4eUOdk' });
    // console.log('FCM token:', token);
    return token;
  } catch (error) {
    console.error('Error getting FCM token', error);
  }
};

interface LoginProps {}
const Login = (props: LoginProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  // const { isUserLogin, error, loginLoading, isUserLogout } = useAppSelector(
  //   state => ({
  //     isUserLogin: state.Login.isUserLogin,
  //     error: state.Login.error,
  //     loginLoading: state.Login.loading,
  //     isUserLogout: state.Login.isUserLogout,
  //   })
  // );


  const errorData = createSelector(
    (state : any) => state.Login,
    (state) => ({
      isUserLogin: state.isUserLogin,
      error: state.error,
      loginLoading: state.loading,
      isUserLogout: state.isUserLogout,
    })
  );
  // Inside your component
  const { isUserLogin, error, loginLoading, isUserLogout } = useAppSelector(errorData);

  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState("/");
  const [data, setData] = useState<any>({
    token: "",
  });
  
  useEffect(() => {
    const getToken = async () => {
      const token = await requestPermission();
      if (token) {
        // Send the token to your server to subscribe the device for notifications
        setData({ token });
      }
    };

    getToken();
  }, []);

  useEffect(() => {
    const url =
      location.state && location.state.from
        ? location.state.from.pathname
        : "/";
    setRedirectUrl(url);
  }, [location]);

  useEffect(() => {
    if (isUserLogin && !loginLoading && !isUserLogout) {
      navigate(redirectUrl);
    }
  }, [isUserLogin, navigate, loginLoading, isUserLogout, redirectUrl]);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup.string().required("Please Enter E-mail."),
      password: yup.string().required("Please Enter Password."),
    })
  );

  const defaultValues: any = {
    email: "",
    password: "",
  };

  const methods = useForm({ defaultValues, resolver });
  const { handleSubmit, register, control, formState: { errors }, } = methods;

  const onSubmitForm = async (values: object) => {
    dispatch(loginUser({
      ...values,
      token: data.token,
    }));
  };

  const { userProfile, loading } = useProfile();

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: redirectUrl }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader title="Welcome Back !" subtitle="Sign in to continue." />

            {error && <Alert color="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit(onSubmitForm)} className="position-relative" >
              {loginLoading && <Loader />}
              <div className="mb-3">
                <FormInput label="Username" type="text" name="email" register={register} errors={errors} control={control} labelClassName="form-label" placeholder="Enter username" className="form-control" />
              </div>

              <div className="mb-3">
                <FormInput label="Password" type="password" name="password" register={register} errors={errors} control={control} labelClassName="form-label" className="form-control pe-5" placeholder="Enter Password" />
              </div>

              <div className="text-center mt-4">
                <Button color="primary" className="w-100" type="submit">
                  Log In
                </Button>
              </div>
            </Form>

          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Login;
