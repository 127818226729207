import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

// components
import LightBox from "../../../components/LightBox";

//images
import imagePlaceholder from "../../../assets/images/users/user-dummy-img.jpg";

// interface
import { MessagesTypes, ImageTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";

// utils
import { formateDate } from "../../../utils";


interface ImageProps {
	image: string;
	onImageClick: () => void;
}
const Image = ({ image, onImageClick }: ImageProps) => {
	return (
		<React.Fragment>
			<div className="message-img-list">
				<Link className="popup-img d-inline-block" to={"#"} onClick={() => onImageClick()}>
					<img src={image} alt="" className="rounded border" />
				</Link>
			</div>
		</React.Fragment>
	);
};
interface ImagesProps {
	images: string;
}
const Images = ({ images }: ImagesProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(0);
	const onImageClick = () => {
		setIsOpen(true);
	};
	const onClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<div className="message-img mb-0">
				{images &&
					<Image
						image={images}
						onImageClick={onImageClick}
					/>
				}
			</div>
			{isOpen && (
				<LightBox
					isOpen={isOpen}
					images={[images]}
					onClose={onClose}
					defaultIdx={selected}
				/>
			)}
		</>
	);
};

interface MessageProps {
	message: MessagesTypes;
	chatUserDetails: any;
}

const Message = ({ message, chatUserDetails }: MessageProps) => {
	const { userProfile } = useProfile();

	const myProfile = userProfile.profileImage ? userProfile.profileImage : imagePlaceholder;
	const chatUserprofile = chatUserDetails.profileImage ? chatUserDetails.profileImage : imagePlaceholder;
	const profile = chatUserprofile;
	const date = formateDate(message.time, "hh:mmaaa");
	const fullName = message.sender;
	const hasImages = message.text?.startsWith("https://chatassistant.blanksoft.dev/assets/") || message.text?.startsWith("https://portal.carshifu.com.my/assets/");
	const isFromMe = !message.isCustomerMessage;
	
	// Function to process the message text
	const processMessageText = (text: string) => {
		if (!text) return [];

		const parts = text.split(/(\*\*|\n)/g); // Split by ** and \n
		let bold = false;

		return parts.map((part, index) => {
			if (part === '**') {
				bold = !bold;
				return null; // Skip the ** marker itself
			}
			if (part === '\n') {
				return <br key={`line-${index}`} />; // Handle new lines
			}
			return bold ? <b key={`bold-${index}`}>{part}</b> : part;
		}).filter(part => part !== null); // Remove null elements
	};

	return (
		<li className={classnames("chat-list", { right: isFromMe })}>
			<div className="conversation-list">
				<div className="chat-avatar">
					<img src={isFromMe ? myProfile : profile} alt="" />
				</div>

				<div className="user-chat-content">
					{
						hasImages ?
							<div className="ctext-wrap">
								{ hasImages && <Images images={message.text!} /> }
							</div>
						: message.text && 
							<div className="ctext-wrap">
								<div className="ctext-wrap-content" style={{ textAlign: "left" }}>
									<p className="mb-0 ctext-content text-left">{processMessageText(message.text)}</p>
								</div>
							</div>
					}
					
					<div className="conversation-name">
						{isFromMe ? (
							<>
								<small className={classnames("text-muted", "mb-0", "me-2")}>
									{date}
								</small>
								{fullName}
							</>
						) : (
							<>
								{fullName}
								<small className={classnames("text-muted", "mb-0", "ms-2")}>
									{date}
								</small>
							</>
						)}
					</div>
				</div>
			</div>
		</li>
	);
};

export default Message;
