import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";

// actions
import { toggleUserDetailsTab, getChatUserConversations, onSendMessage } from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";

const Index = () => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state : any) => state.Chats,
    (state) => ({
      chatUserDetails: state.chatUserDetails,
      chatUserConversations: state.chatUserConversations,
      tags: state.tags,
      isUserMessageSent: state.isUserMessageSent,
      isMessageDeleted: state.isMessageDeleted,
      isMessageForwarded: state.isMessageForwarded,
      isUserMessagesDeleted: state.isUserMessagesDeleted,
      isImageDeleted: state.isImageDeleted,
    })
  );
  // Inside your component
  const { chatUserDetails, chatUserConversations, isUserMessageSent, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted, isImageDeleted, tags} = useAppSelector(errorData);

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<null | MessagesTypes | undefined>();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    dispatch(onSendMessage(data));
    setReplyData(null);
  };


  useEffect(() => {
    if (isUserMessageSent || isMessageDeleted || isMessageForwarded || isUserMessagesDeleted || isImageDeleted) {
      dispatch(getChatUserConversations(chatUserConversations.data.result.meta.conversationId, chatUserConversations.data.result.meta.companyId));
    }
  }, [dispatch, isUserMessageSent, chatUserConversations, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted, isImageDeleted]);

  return (
    <>
      <UserHead
        chatUserDetails={chatUserConversations && chatUserConversations.data?.result?.meta}
        tags={chatUserConversations && chatUserConversations.data?.result?.tags}
        onOpenUserDetails={onOpenUserDetails}
        tagOptions={tags}
        chatID={chatUserConversations && chatUserConversations.data?.result?.meta?.conversationId}
        companyID={chatUserConversations && chatUserConversations.data?.result?.meta?.companyId}
      />
      <Conversation
        chatUserConversations={chatUserConversations && chatUserConversations.data ? chatUserConversations.data.result : {}}
        chatUserDetails={chatUserDetails}
        onSetReplyData={onSetReplyData}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserConversations && chatUserConversations.data?.result?.meta}
      />
    </>
  );
};

export default Index;
