import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ChatsActionTypes } from "./types";
import { chatsApiResponseSuccess, chatsApiResponseError } from "./actions";

import {
  getFavourites as getFavouritesApi,
  getDirectMessages as getDirectMessagesApi,
  getArchivedMessages as getArchivedMessagesApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  receiveMessageFromUser as receiveMessageFromUserApi,
  deleteMessage as deleteMessageApi,
  forwardMessage as forwardMessageApi,
  deleteUserMessages as deleteUserMessagesApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
  getTags as getTagsApi,
  addTags as addTagsApi,
  deleteTags as deleteTagsApi,
  setAutoReplyOff as setAutoReplyOffApi,
  setAutoReplyOn as setAutoReplyOnApi,
  setArchive as setArchiveApi,
  setUnArchive as setUnArchiveApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {
  getDirectMessages as getDirectMessagesAction,
  getFavourites as getFavouritesAction,
} from "./actions";

function* getFavourites() {
  try {
    const response: Promise<any> = yield call(getFavouritesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_FAVOURITES, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_FAVOURITES, error));
  }
}

function* getDirectMessages() {
  try {
    const response: Promise<any> = yield call(getDirectMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_DIRECT_MESSAGES, error));
  }
}

function* getArchivedMessages() {
  try {
    const response: Promise<any> = yield call(getArchivedMessagesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVED_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVED_MESSAGES, error)
    );
  }
}

function* getChatUserConversations({ payload: {selectedChat, companyID} }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, selectedChat, companyID);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error));
  }
}

function* onSendMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendMessage, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSAGE, error));
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}

function* receiveMessageFromUser({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
    );
  }
}

function* deleteMessage({ payload: { userId, messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      userId,
      messageId
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error));
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error));
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_USER_MESSAGES, error)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    yield put(getDirectMessagesAction());
    yield put(getFavouritesAction());
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

function* getTags() {
  try {
    const response: Promise<any> = yield call(getTagsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_TAGS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_TAGS, error));
  }
}

function* addTags({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(addTagsApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.ADD_TAGS, response));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyID);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
    const getresponse: Promise<any> = yield call(getDirectMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, getresponse));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_TAGS, error));
  }
}

function* deleteTags({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(deleteTagsApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_TAGS, response));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyID);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
    const getresponse: Promise<any> = yield call(getDirectMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, getresponse));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_TAGS, error));
  }
}

function* setAutoReplyOff({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(setAutoReplyOffApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.SET_AUTO_REPLY_OFF, response));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyId);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SET_AUTO_REPLY_OFF, error));
  }
}

function* setAutoReplyOn({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(setAutoReplyOnApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.SET_AUTO_REPLY_ON, response));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyId);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SET_AUTO_REPLY_ON, error));
  }
}

function* setArchive({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(setArchiveApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.SET_ARCHIVE, response));
    const getDirectMessages: Promise<any> = yield call(getDirectMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages));
    const getArchivedMessages: Promise<any> = yield call(getArchivedMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVED_MESSAGES, getArchivedMessages));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyId);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SET_ARCHIVE, error));
  }
}

function* setUnArchive({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(setUnArchiveApi, data);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.SET_UN_ARCHIVE, response));
    const getDirectMessages: Promise<any> = yield call(getDirectMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages));
    const getArchivedMessages: Promise<any> = yield call(getArchivedMessagesApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVED_MESSAGES, getArchivedMessages));
    const getChatUserConversations: Promise<any> = yield call(getChatUserConversationsApi, data.id, data.companyId);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, getChatUserConversations));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SET_UN_ARCHIVE, error));
  }
}


export function* watchGetFavourites() {
  yield takeEvery(ChatsActionTypes.GET_FAVOURITES, getFavourites);
}

export function* watchGetDirectMessages() {
  yield takeEvery(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
}
export function* watchGetArchivedMessages() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVED_MESSAGES, getArchivedMessages);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
export function* watchReceiveMessageFromUser() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
    receiveMessageFromUser
  );
}
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}
export function* watchGetTags() {
  yield takeEvery(ChatsActionTypes.GET_TAGS, getTags);
}
export function* watchAddTags() {
  yield takeEvery(ChatsActionTypes.ADD_TAGS, addTags);
}
export function* watchDeleteTags() {
  yield takeEvery(ChatsActionTypes.DELETE_TAGS, deleteTags);
}
export function* watchSetAutoReplyOff() {
  yield takeEvery(ChatsActionTypes.SET_AUTO_REPLY_OFF, setAutoReplyOff);
}
export function* watchSetAutoReplyOn() {
  yield takeEvery(ChatsActionTypes.SET_AUTO_REPLY_ON, setAutoReplyOn);
}
export function* watchSetArchive() {
  yield takeEvery(ChatsActionTypes.SET_ARCHIVE, setArchive);
}
export function* watchSetUnArchive() {
  yield takeEvery(ChatsActionTypes.SET_UN_ARCHIVE, setUnArchive);
}

function* chatsSaga() {
  yield all([
    fork(watchGetFavourites),
    fork(watchGetDirectMessages),
    fork(watchGetArchivedMessages),
    fork(watchGetChatUserConversations),
    fork(watchOnSendMessage),
    fork(watchReceiveMessage),
    fork(watchReadMessage),
    fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchGetTags),
    fork(watchAddTags),
    fork(watchDeleteTags),
    fork(watchSetAutoReplyOff),
    fork(watchSetAutoReplyOn),
    fork(watchSetArchive),
    fork(watchSetUnArchive),
  ]);
}

export default chatsSaga;
