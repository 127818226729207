import React, { useState } from "react";
import { createSelector } from "reselect";



// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { toggleUserDetailsTab, setAutoReplyOff, setAutoReplyOn, getChatUserConversations } from "../../../redux/actions";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import ProfileUser from "./ProfileUser";

const Index = () => {
	// global store
	const { dispatch, useAppSelector } = useRedux();

	const errorData = createSelector(
		(state : any) => state.Chats,
		(state) => ({
			chatUserDetails: state.chatUserDetails,
			getUserDetailsLoading: state.getUserDetailsLoading,
			isOpenUserDetails: state.isOpenUserDetails,
			chatUserConversations: state.chatUserConversations,
		})
	);

	// Inside your component
	const { getUserDetailsLoading, isOpenUserDetails, chatUserConversations } = useAppSelector(errorData);

	const isAutoReplyOn = chatUserConversations.data?.result?.meta?.isAutoReplyEnable;
	const autoReplyEnableAfter = chatUserConversations.data?.result?.meta?.autoReplyEnableAfter;
	const conversationId = chatUserConversations.data?.result?.meta?.conversationId;
	const companyId = chatUserConversations.data?.result?.meta?.companyId;

	/*
	close tab
	*/
	const onCloseUserDetails = () => {
		dispatch(toggleUserDetailsTab(false));
	};

	const handleEnableAutoReplyClick = (conversationId: string, companyId: number) => {
		dispatch(setAutoReplyOn({ id: conversationId, companyId }));
	};

	const handleDisableAutoReplyClick = (conversationId: string, companyId: number) => {
		dispatch(setAutoReplyOff({ id: conversationId, companyId }));
	};


	return (
		<div className={`user-profile-sidebar ${isOpenUserDetails ?  "d-block" : ""}`} >
			<div className="position-relative">
				{getUserDetailsLoading && <Loader />}

				<ProfileUser
					onCloseUserDetails={onCloseUserDetails}
					chatUserDetails={chatUserConversations.data?.result?.meta}
				/>
				{/* <!-- End profile user --> */}

				{/* <!-- Start user-profile-desc --> */}
				<AppSimpleBar className="p-4 user-profile-desc">
				{" "}
				{/* simplebar */}
				{/* 
					<Actions
						chatUserDetails={chatUserDetails}
						onToggleFavourite={onToggleFavourite}
					/> 
				*/}
				{/* <Status about={chatUserDetails.about} /> */}
				{/* <Members chatUserDetails={chatUserDetails} /> */}
				{/* <hr className="my-4" /> */}

				{
					isAutoReplyOn ? (
						<div className="mb-4">
							<h5 className="font-size-14">Auto Reply</h5>
							<p className="text-muted">Auto reply is enabled</p>
							<button className="btn btn-danger btn-sm" onClick={() => handleDisableAutoReplyClick(conversationId, companyId)}>Disable Auto Reply</button>
						</div>
					) : (
						<div className="mb-4">
							<h5 className="font-size-14">Auto Reply</h5>
							<p className="text-muted">{`Auto reply will be enabled after ${autoReplyEnableAfter}`}</p>
							<button className="btn btn-primary btn-sm" onClick={() => handleEnableAutoReplyClick(conversationId, companyId)}>Enable Auto Reply</button>
						</div>
					)
				}
				
				</AppSimpleBar>
				{/* <!-- end user-profile-desc --> */}
			</div>
		</div>
	);
};

export default Index;
