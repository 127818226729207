import React from "react";

// hooks
import { useRedux } from "../../hooks/index";

// hooks
import { useConversationUserType } from "../../hooks/index";
import { createSelector } from "reselect";

// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { useAppSelector } = useRedux();

  // const { selectedChat } = useAppSelector(state => ({
  //   selectedChat: state.Chats.selectedChat,
  // }));
  const errorData = createSelector(
    (state : any) => state.Chats,
    (state) => ({ selectedChat: state.selectedChat })
  );

  // Inside your component
  const { selectedChat} = useAppSelector(errorData);

  return (
    <>
      <Leftbar />

      <div className={`user-chat w-100 overflow-hidden ${ selectedChat && "user-chat-show" }`} id="user-chat">
        <div className="user-chat-overlay overflow-hidden" id="user-chat-overlay"></div>
        {selectedChat !== null ? (
          <div className="chat-content d-lg-flex overflow-hidden">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser />
            </div>
            <UserProfileDetails />
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};

export default Index;
