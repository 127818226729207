export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",
  GET_ARCHIVED_MESSAGES = "@@chats/GET_ARCHIVED_MESSAGES",

  ADD_CONTACTS = "@@chats/ADD_CONTACTS",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",

  // MESSAGE
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  TOGGLE_FAVOURITE_CONTACT = "@@chats/TOGGLE_FAVOURITE_CONTACT",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",
  REPLY_IMAGE = "@@chats/REPLY_IMAGE",
  SET_AUTO_REPLY_OFF = "@@chats/SET_AUTO_REPLY_OFF",
  SET_AUTO_REPLY_ON = "@@chats/SET_AUTO_REPLY_ON",
  SET_ARCHIVE = "@@chats/SET_ARCHIVE",
  SET_UN_ARCHIVE = "@@chats/SET_UN_ARCHIVE",

  // TAGS
  GET_TAGS = "@@chats/GET_TAGS",
  ADD_TAGS = "@@chats/ADD_TAGS",
  DELETE_TAGS = "@@chats/DELETE_TAGS",
}
export interface ChatsState {
  favourites: Array<any>;
  directMessages: Array<any>;
  archivedMessages: Array<any>;
  selectedChat: string | number | null;
  chatUserDetails: object;
  chatUserConversations: {};
  isOpenUserDetails: boolean;
  archiveMessages: Array<any>;
  tags: Array<any>;
}
