import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const postJwtLogin = (data: any) => {
  return api.create(url.POST_LOGIN, data)
    .then(response => ({
      data: response.data, // assuming response from create contains { data, status }
      status: response.status
    }))
    .catch(error => {
      // Adjust this according to how errors are structured
      return {
        data: {}, // Default or extracted error information
        status: 500 // Default or extracted error status
      };
    });
};

export {
  postJwtLogin,
};
