import React, { useState } from "react";
import { Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";

import { useRedux } from "../../../hooks/index";

import { getChatUserConversations, setArchive, setUnArchive, getDirectMessages, getArchivedMessages } from "../../../redux/actions";


//images
import imagePlaceholder from "../../../assets/images/users/user-dummy-img.jpg";

// constants
import { STATUS_TYPES } from "../../../constants";
interface ProfileUserProps {
	onCloseUserDetails: () => any;
	chatUserDetails: any;
}
const ProfileUser = ({
	onCloseUserDetails,
	chatUserDetails,
}: ProfileUserProps) => {
	const { dispatch, useAppSelector } = useRedux();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	const profile = chatUserDetails?.profileImage ? chatUserDetails.profileImage : imagePlaceholder;
	const fullName =
		chatUserDetails?.title === undefined
			? chatUserDetails?.title ? `${chatUserDetails.title}` : "-"
			: chatUserDetails?.title;

	const conversationId = chatUserDetails?.conversationId;
	const companyId = chatUserDetails?.companyId;
	const isArchived = chatUserDetails?.isArchived;

	const handleClickArchive = () => {
		dispatch(setArchive({ id: conversationId, companyId }));
	}

	const handleClickUnArchive = () => {
		dispatch(setUnArchive({ id: conversationId, companyId }));
	}

	return (
		<div className="p-3 border-bottom">
			<div className="user-profile-img">
				<img src={profile} className="profile-img rounded" alt="" />
				<div className="overlay-content rounded">
					<div className="user-chat-nav p-2">
						<div className="d-flex w-100">
							<div className="flex-grow-1">
								<Button color="none" type="button" className="btn nav-btn text-white user-profile-show d-none d-lg-block" onClick={onCloseUserDetails}>
									<i className="bx bx-x"></i>
								</Button>
								<Button type="button" color="none" className="btn nav-btn text-white user-profile-show d-block d-lg-none" onClick={onCloseUserDetails}>
									<i className="bx bx-left-arrow-alt"></i>
								</Button>
							</div>
							<div className="flex-shrink-0">
								<Dropdown isOpen={dropdownOpen} toggle={toggle}>
									<DropdownToggle color="none" className="btn nav-btn text-white" type="button">
										<i className="bx bx-dots-vertical-rounded"></i>
									</DropdownToggle>
									<DropdownMenu className="dropdown-menu-end">
										{/* 
										<DropdownItem className="d-flex justify-content-between align-items-center d-lg-none user-profile-show" to="#">
											View Profile <i className="bx bx-user text-muted"></i>
										</DropdownItem>
										 */}
										{
											isArchived ?
												<DropdownItem className="d-flex justify-content-between align-items-center" to="#" onClick={() => handleClickUnArchive()}>
													Un-Archive <i className="bx bx-archive text-muted"></i>
												</DropdownItem>
											:
												<DropdownItem className="d-flex justify-content-between align-items-center" to="#" onClick={() => handleClickArchive()}>
													Archive <i className="bx bx-archive text-muted"></i>
												</DropdownItem>
										}
										
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>
					<div className="mt-auto p-3">
						<h5 className="user-name mb-1 text-truncate">{fullName}</h5>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileUser;
