import React, { useEffect } from 'react';

// scss
import "./assets/scss/theme.scss";

//Route
import Routes from "./routes";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { getDirectMessages, getChatUserConversations } from "./redux/actions";
import { useRedux } from "./hooks/index";

import * as url from "./api/urls";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBV4B8jjMCuJAA-Tab3gWr8T5X3YHfSVN4",
  authDomain: "chatassistant-478e6.firebaseapp.com",
  projectId: "chatassistant-478e6",
  storageBucket: "chatassistant-478e6.appspot.com",
  messagingSenderId: "1056359695751",
  appId: "1:1056359695751:web:195e58425851b6a2023f22",
  measurementId: "G-0N16LYYBYS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

// // init firebase backend
// initFirebaseBackend(firebaseConfig);

export const requestPermission = async () => {
  try {
    await Notification.requestPermission();
    const token = await getToken(messaging, { vapidKey: 'BCMdMLEOfWjd6pENTag2sifPxQnaINlGRSv6PIwlgTAVFekIV5aZPpeLMInXfk-F110esVLzRQ8a7f-Xs4eUOdk' });
    // console.log('FCM token:', token);
    return token;
  } catch (error) {
    console.error('Error getting FCM token', error);
  }
};


interface IndexProps {}
const App = (props: IndexProps) => {
  document.title = "ChatAssistant"

	const { dispatch, useAppSelector } = useRedux();

  const { chatUserConversations } = useAppSelector(state => ({
    chatUserConversations: state.Chats.chatUserConversations,
  }));


  useEffect(() => {
    document.title = 'ChatAssistant';

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function (registration) {
          // Listen for messages from service worker
          navigator.serviceWorker.addEventListener('message', event => {
            if (event.data && event.data.type === 'REFRESH_DATA') {
              console.log('Refresh data message received:', event.data.payload);
              // Dispatch your Redux action to refresh data
              // dispatch(getDirectMessages());
            }
          });
        }).catch(function (err) {
          console.log('Service Worker registration failed: ', err);
        });
    }

    // Clean up the interval on component unmount
    return;
  }, [dispatch]);

  useEffect(() => {
    const getToken = async () => {
      const token = await requestPermission();
      if (token) {
        // Send the token to your server to subscribe the device for notifications
        await fetch(url.BACKEND_URL + '/auth/register-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getBackendToken(),
          },
          body: JSON.stringify({ token }),
        });
      }
    };

    getToken();
  }, []);

  const getBackendToken = () => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const user = JSON.parse(authUser);
      return user.token;
    }
    return "";
  }

  onMessage(messaging, (payload) => {
    // console.log('Message received. ', payload);
    // Handle foreground messages here
    dispatch(getDirectMessages());
    const companyID = parseInt(payload.data?.companyID ?? '0');
    // console.log('chatUserConversations', chatUserConversations.data?.result.meta.conversationId, companyID)
    if (payload.data?.chatId === chatUserConversations.data?.result.meta.conversationId && companyID === chatUserConversations.data?.result.meta.companyId) {
      // Dispatch your Redux action to update the UI
      dispatch(getChatUserConversations(chatUserConversations.data?.result.meta.conversationId, companyID));
    }
  });

  return <Routes />;
};

export default App;

// <!--
// manifest.json provides metadata used when your web app is installed on a
// user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
// -->
// <!--
// Notice the use of %PUBLIC_URL% in the tags above.
// It will be replaced with the URL of the `public` folder during the build.
// Only files inside the `public` folder can be referenced from the HTML.

// Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
// work correctly both with client-side routing and a non-root public URL.
// Learn how to configure a non-root public URL by running `npm run build`.
// -->
// <!--
// This HTML file is a template.
// If you open it directly in the browser, you will see an empty page.

// You can add webfonts, meta tags, or analytics to this file.
// The build step will place the bundled scripts into the <body> tag.

// To begin the development, run `npm start` or `yarn start`.
// To create a production bundle, use `npm run build` or `yarn build`.
// -->