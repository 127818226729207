import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { useRedux } from "../hooks/index";

import { addTags, deleteTags, getChatUserConversations } from "../redux/actions";

// interface
import { PinTypes } from "../data/chat";

interface AddPinnedTabModalProps {
	isOpen: boolean;
	onClose: () => void;
	selectedTags: Array<PinTypes>;
	tagOptions: Array<PinTypes>;
	chatID: string;
	companyID: number;
}
const AddPinnedTabModal = ({ isOpen, onClose, selectedTags, tagOptions, chatID, companyID }: AddPinnedTabModalProps) => {
	const { dispatch, useAppSelector } = useRedux();

	const tags = selectedTags.map((tag) => tag.id);

	const tagNotSelected = tagOptions.filter((tag) => {
		return !selectedTags.some((selectedTag) => selectedTag.id === tag.id);
	});

	const handleAddTag = (id: string, companyID: number, tagID: string) => () => {
		dispatch(addTags({ id, tagID, companyID }));
	};

	const handleDeleteTag = (id: string, companyID: number, tagID: string) => () => {
		dispatch(deleteTags({ id, tagID, companyID }));
	};

	return (
		<Modal isOpen={isOpen} toggle={onClose} className="pinnedtabModal" tabIndex={-1} contentClassName="shadow-lg border-0" centered scrollable>
			<ModalBody className="p-0 ">
				<ModalHeader toggle={onClose} className="bg-primary">
					<div className="modal-title modal-title-custom text-white bg-primary font-size-16">Tags</div>
				</ModalHeader>
				<div className="modal-body p-4">
					<div className="d-flex align-items-center mb-3">
						<div className="flex-grow-1">
							<div>
								<h5 className="font-size-16 mb-0">
								{
									selectedTags && selectedTags.length
										? selectedTags.length === 1 
											? selectedTags.length + " Tag"
											: selectedTags.length + " Tags"
										: "No Tags"
								} 
								</h5>
							</div>
						</div>
					</div>

					<span className="d-block mb-3">Select Tags</span>
					<div className="d-flex align-items-center mb-5">
						<div className="flex-grow-1">
						{
							(tagNotSelected || []).map((tag: PinTypes, key: number) => {
								return (
									<button type="button" key={key} className="btn btn-sm btn-soft-primary d-inline-block me-2" onClick={handleAddTag(chatID, companyID, tag.id)}>
										{tag.title}
									</button>
								)
							})
						}
						</div>
					</div>

					<span className="d-block mb-3">Selected Tags</span>
					<div className="d-flex align-items-center mb-3">
						<div className="flex-grow-1">
							{(selectedTags || []).map((tag: PinTypes, key: number) => {
								return (
									<button type="button" key={key} className="btn btn-sm btn-soft-primary d-inline-block me-2" onClick={handleDeleteTag(chatID, companyID, tag.id)}>
										{tag.title}
									</button>
								)
							})}
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default AddPinnedTabModal;
