import { combineReducers } from "redux";

import Login from "./auth/login/reducer";
import Layout from "./layout/reducer";
import Profile from "./profile/reducer";
import Bookmarks from "./bookmarks/reducer";
import Settings from "./settings/reducer";
import Chats from "./chats/reducer";

export default combineReducers({
  Login,
  Layout,
  Profile,
  Bookmarks,
  Settings,
  Chats,
});
