import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { UserTypes } from "../../../data/chat";

interface ChatUserProps {
  user: UserTypes;
  selectedChat: string | number;
  onSelectChat: (id: number | string, companyID: number) => void;
  tagsOptions: Array<any>;
}
const ChatUser = ({ user, selectedChat, tagsOptions, onSelectChat }: ChatUserProps) => {
  const fullName = `${user.name}`;
  const shortName = `${user.name.charAt(0)}`;
  const selectedTags = JSON.parse(user && user.tags ? user.tags : "[]");
  const lastMessage = user.lastMessage ?? "";
  const lastMessageFormat = lastMessage.length > 50 ? lastMessage.substring(0, 50) + "..." : lastMessage;


  const colors = [
    "bg-primary",
    // "bg-danger",
    // "bg-info",
    // "bg-warning",
    // "bg-secondary",
    // "bg-pink",
    // "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  // const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;
  const unRead = user.meta && user.meta.unRead;

  const isSelectedChat: boolean = selectedChat && selectedChat === user.id ? true : false;
  
  const onClick = () => {
    onSelectChat(user.id, user.companyID);
  };

  const formatDate = (date: Date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}


  return (
    <li className={classnames({ active: isSelectedChat }, "chat-item")} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div className={classnames("chat-user-img", "align-self-center", "me-2", "ms-0", )}>
            {/* { online: isOnline } */}
            {user.profileImage ? (
              <>
                <img
                  src={user.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {/* {isOnline && <span className="user-status"></span>} */}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username">{shortName}</span>
                  {/* <span className="user-status"></span> */}
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{fullName}</p>
            <p className="text-truncate mb-0 last-message">{lastMessageFormat}</p>
            <p className="text-truncate mb-0 last-message-time">{user.lastMessageTime}</p>
            {
              selectedTags.map((tag: any, key: number) => {
                const tagData = tagsOptions.find((item: any) => item.id === tag);
                if (!tagData) return null;
                return (
                  <span key={key} className="tag-label">{tagData.title}</span>
                )
              })
            }
          </div>
          {unRead && unRead !== 0 ? (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};

export default ChatUser;
